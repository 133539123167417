<template>
  <div class="p-4">
    <div>
      <span class="font-medium">{{$t('channel.settings.labels.language')}}</span>
      <el-dropdown
        class="ml-3"
        trigger="click"
        @command="handleLanguageSelect">
        <button
          class="button"
          :class="{'button_loading': languageLoading}">
          {{$t(`channel.languages.${channel.settings.lang}`)}}
          <el-icon name="arrow-down"/>
        </button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="language in languages"
            :key="language"
            :command="language">
            {{$t(`channel.languages.${language}`)}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="font-medium py-2">
      <span class="mr-4">
        {{$t('channel.settings.labels.timezone')}}
      </span>
      <span>{{channelTimezone}}</span>
    </div>

    <div class="font-medium flex flex-row">
      <CheckboxFormItem
        :value="channel.settings.require_subscription"
        @input="saveRequireSubscription"
        :label="$t('channel.settings.labels.requireSubscription')"/>
      <el-tooltip
        effect="dark"
        :content="$t('channel.settings.requireSubscriptionTooltipText')"
        placement="top">
        <i class="material-icons ml-2 my-auto text-xl text-gray-700">help</i>
      </el-tooltip>
    </div>

    <div class="font-medium max-w-2xl flex flex-row" v-if="channel.settings.require_subscription">
      <span class="mr-4 flex-shrink-0 my-auto">
        {{$t('channel.settings.labels.requireSubscriptionAlertText')}}
      </span>
      <el-input
        :placeholder="$t('channel.settings.requireSubscriptionPlaceholder')"
        v-model="requireSubscriptionAlertText"/>
      <button
        class="ml-2 button button_type_filled my-auto"
        :class="{
            'button_loading': saveButtonLoading,
            'post__button_theme-change': saveButtonThemeChange,
            'button_theme_success': saveButtonThemeSuccess,
            'button_theme_danger': saveButtonThemeDanger,
            'button_effect_shake': saveButtonEffectShake,
          }"
        @click="saveRequireSubscriptionText">
        {{$t('common.actions.save')}}
      </button>
    </div>

    <div class="font-medium flex flex-row">
      <CheckboxFormItem
        :value="channel.settings.require_subscription_show_alert"
        @input="saveRequireSubscriptionShowAlert"
        :label="$t('channel.settings.labels.requireSubscriptionShowAlert')"/>
      <el-tooltip
        effect="dark"
        :content="$t('channel.settings.requireSubscriptionShowAlertTooltipText')"
        placement="top">
        <i class="material-icons ml-2 my-auto text-xl text-gray-700">help</i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
  import Blinkable from '@/components/Blinkable.vue'
  import CheckboxFormItem from '@/components/form/CheckboxFormItem.vue'

  import {
    enableForMixin,
  } from '@/mixins/common'

  import {
    nextTick,
  } from '@/mixins/utils'

  import {
    apiSetLanguage,
    apiSetRequireSubscription,
    apiSetRequireSubscriptionAlertText,
    apiSetRequireSubscriptionShowAlert,
  } from '@/functions/api'

  const THEME_CHANGE_DURATION = 200
  const SUCCESS_HIGHLIGHT_DURATION = 3000
  const DANGER_HIGHLIGHT_DURATION = 3000
  const SHAKE_DURATION = 500

  export default {
    name: 'ChannelSettings',
    mixins: [
      enableForMixin,
      nextTick,
    ],
    components: {
      Blinkable,
      CheckboxFormItem,
    },
    props: {
      channel: Object,
    },
    data() {
      return {
        languages: [
          'ru', 'en', 'ua', 'uz', 'uz_cyrillic',
        ],

        requireSubscriptionAlertText: this.channel.settings.require_subscription_alert_text,

        languageLoading: false,

        saveButtonLoading: false,
        saveButtonThemeChange: false,
        saveButtonThemeSuccess: false,
        saveButtonThemeDanger: false,
        saveButtonEffectShake: false,
      }
    },
    methods: {
      async handleLanguageSelect(value) {
        if (this.channel.settings && this.channel.settings.lang && this.channel.settings.lang === value) {
          return
        }

        this.languageLoading = true
        const result = await apiSetLanguage({cid: this.channel.cid, value})
        this.languageLoading = false

        if (result.ok) {
          this.$emit('update:channel', result.result.channel)
        }
      },

      async saveRequireSubscription(value) {
        const result = await apiSetRequireSubscription({cid: this.channel.cid, value})

        if (result.ok) {
          this.$emit('update:channel', result.result.channel)
        }
      },

      async saveRequireSubscriptionText() {
        this.saveButtonLoading = true
        const result = await apiSetRequireSubscriptionAlertText({
          cid: this.channel.cid,
          value: this.requireSubscriptionAlertText
        })
        this.saveButtonLoading = false

        if (!result.ok) {
          this.enableFor('saveButtonThemeChange', DANGER_HIGHLIGHT_DURATION + THEME_CHANGE_DURATION)
          await this.nextTick()
          this.enableFor('saveButtonThemeDanger', DANGER_HIGHLIGHT_DURATION)
          this.enableFor('saveButtonEffectShake', SHAKE_DURATION)
          return
        }

        this.enableFor('saveButtonThemeChange', SUCCESS_HIGHLIGHT_DURATION + THEME_CHANGE_DURATION)
        await this.nextTick()
        this.enableFor('saveButtonThemeSuccess', SUCCESS_HIGHLIGHT_DURATION)

        this.$emit('update:channel', result.result.channel)
        this.requireSubscriptionAlertText = result.result.channel.settings.require_subscription_alert_text
      },

      async saveRequireSubscriptionShowAlert(value) {
        const result = await apiSetRequireSubscriptionShowAlert({cid: this.channel.cid, value})

        if (result.ok) {
          this.$emit('update:channel', result.result.channel)
        }
      },
    },
    computed: {
      channelTimezone() {
        return this.channel && this.channel.settings && this.channel.settings.timezone_id || 'Etc/GMT'
      },
    },
  }
</script>

<style scoped lang="stylus">
  .channel-admins__admins-list
    li
      @apply px-4 py-3
      @apply border-t border-gray-300
</style>